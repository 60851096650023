import React from "react"
import PropTypes from "prop-types"

export default function Section(props) {
  return (
    <section id={props.id} className="section-conteneur">
      <h2 className="section-titre">{props.titre}</h2>
      {props.children}
    </section>
  )
}

Section.propTypes = {
  id: PropTypes.string,
  children: PropTypes.object,
  titre: PropTypes.string,
}
