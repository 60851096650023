import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

import Lien from "./Lien"

export default function CarteTitreEtDescription({ page }) {
  if (page.vignette.fichier == null || page.vignette.fichier == undefined)
    throw new Error(
      `Aucune vignette n'a été créée par Gatsby pour la page ${page.code}. Vérifiez l'url de la vignette dans le fichier Pages.yaml ou elle est déclarée.\n` +
        `Tentez également de provoquer une nouvelle exécution des requêtes statiques sur le fichier pages-compilation.yaml en le modifiant et en le sauvegardant.`
    )
  return (
    <Lien codePageCible={page.code}>
      <article className="carte-titre-et-description">
        <GatsbyImage
          image={page.vignette.fichier.childImageSharp.gatsbyImageData}
          alt={page.titre}
        />
        <header>
          <h3>{page.titre_pour_seo_et_lien}</h3>
        </header>
        <p>{page.description}</p>
      </article>
    </Lien>
  )
}

CarteTitreEtDescription.propTypes = {
  page: PropTypes.object,
}
