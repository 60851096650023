import React from "react"
import PropTypes from "prop-types"

import ListeCartesTitreEtDescription from "./ListeCartesTitreEtDescription"

import { locales } from "../../../locales"

export default function PourAllerPlusLoin({ page, pagesPourAllerPlusLoin }) {
  if (
    pagesPourAllerPlusLoin != undefined &&
    pagesPourAllerPlusLoin.length > 0
  ) {
    return (
      <section id="pour-aller-plus-loin-section" className="section-conteneur">
        <h2 className="section-titre">
          {locales[page.langue].site.pourAllerPlusLoin}
        </h2>
        <ListeCartesTitreEtDescription
          pages={pagesPourAllerPlusLoin}
          id="pour-aller-plus-loin-nav"
        />
      </section>
    )
  } else return <div></div>
}

PourAllerPlusLoin.propTypes = {
  page: PropTypes.object,
  pagesPourAllerPlusLoin: PropTypes.array,
}
