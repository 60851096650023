import React from "react"
import PropTypes from "prop-types"

import LayoutEN from "./LayoutEN"
import PourAllerPlusLoin from "../../navigation/PourAllerPlusLoin.js"

export default function LayoutTutorielEN(props) {
  return (
    <LayoutEN page={props.page}>
      {props.children}
      <PourAllerPlusLoin
        page={props.page}
        pagesPourAllerPlusLoin={props.pagesPourAllerPlusLoin}
      />
    </LayoutEN>
  )
}

LayoutTutorielEN.propTypes = {
  children: PropTypes.array.isRequired,
  page: PropTypes.object,
  pagesPourAllerPlusLoin: PropTypes.array,
}
