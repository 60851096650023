import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../../composants/image/Image"
import LayoutTutorielEN from "../../../composants/layout/en/LayoutTutorielEN"
import Lien from "../../../composants/navigation/Lien"
import Section from "../../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../../js/client-es/utils"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pagePhotographierVoieLactee: allPagesCompilationYaml(
      filter: { code: { eq: "photographierLaVoieLacteeEN" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "traitementImagesVoieLacteeEN"
            "voieLacteeIntegralite360EN"
            "galerieVoieLacteeEN"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: { code: { in: ["voieLacteeIntegralite", "bulbeZetaOphiuchi"] } }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "voieLacteeMateriel"
            "voieLacteeCarteBulbe"
            "m8m20LaguneTrifide"
          ]
        }
      }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPortraitMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["voieLacteeBouee", "voieLacteePhare"] } }
    ) {
      ...FragmentImagePortraitMoyen
    }
    imagesPortraitPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: ["voieLacteeWindy", "materielUnemontureEquatorialeStarAdventurer"]
        }
      }
    ) {
      ...FragmentImagePortraitPetit
    }
  }
`

export default function PhotographTheMilkyWay() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pagePhotographierVoieLactee")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPortraitMoyen",
    "imagesPortraitPetit"
  )

  /*nécessaire si le type JSONLD de la page est défini comme étant "f/AQ"*/
  page.jsonLDFAQ = [
    {
      question: "How to spot the Milky Way?",
      reponse:
        "Although the Milky Way is visible a large part of the year in our starry skies, its bulb is visible only from mid-April at the end of the night until mid-September early at night. To find it, locate the Scorpius and Sagittarius constellations (on the southern horizon for the northern hemisphere).",
    },
    {
      question: "Where to photograph the Milky Way?",
      reponse:
        "You must spot a place well spared by light pollution. In the northern hemisphere, the southern horizon must be clear to succeed in your clichés of the Milky Way and its bulb.",
    },
    {
      question: "How to monitor the weather?",
      reponse:
        "Of course, avoid clouds. Multiply your sources of weather information to obtain a reliable forecast. I recommend you to check 3 different weather models before planning an astronomical outing.",
    },
    {
      question: "Which camera should I use?",
      reponse:
        "Any camera capable of long enough exposures can do the job. But for this very demanding subjet, a DSLR or an hybrid camera will be more efficient because often less noisy in high sensitivities.",
    },
    {
      question: "Which photo lense to choose?",
      reponse:
        "It all depends on the photos you want to take. Avoid focal lengths too long (more than 135 mm) because the earth turns and without tracking device, your stars will become oval in less than 5s. A lense open at f/2.8 or less is ideal. A less luminous optics may be suitable, provided you climb in sensitivity or multiply the unit exposures.",
    },
    {
      question: "Which accessories?",
      reponse:
        "A tripod is essential, as well as a remote control to avoid vibrating the device during triggering (use the self-timer otherwise).",
    },
    {
      question: "Which settings to use?",
      reponse:
        "Check that your DSLR is in RAW mode. Otherwise, the compression of images will destroy the weakest stars and your photo will lose details. Open your lense to the maximum. Then determine the ISO setting: on my EOS 6D, I remain the most of the time at 3200 ISO.",
    },
    {
      question: "How to focus?",
      reponse:
        "The best way is to target a brilliant star and magnify it at maximum using the LiveView. Then do the focus manually: the star must become as small as possible.",
    },
  ]

  return (
    <LayoutTutorielEN
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Introduction">
        <p>
          It’s a beautiful summer night. You raise your eyes. The Moon is not
          raised yet. At the zenith, a ribbon of stars stretches from north to
          south and sparkle with a thousand lights. It is the Milky Way, our
          galaxy. Its name would come from Greek mythology. Zeus, wanting to
          make his son Hercules immortal, made him drink the milk of his wife
          Hera while she slept. When she tore off Hercules from her breast, a
          giclee of milk spread in the sky and formed the Milky Way.
        </p>
        <p>
          Like billions of galaxies of its kind, the Milky Way has the form of a
          disk. As we live “inside”, we see it by the slice. The diameter of the
          Milky Way is estimated to <strong>100&nbsp;000 light years</strong>{" "}
          (10^17 km!) and it counts about 100 billions of stars. Under a very
          black sky, this swarm of stars, whose light took several hundred years
          to reach us, can project your shadow on the ground. This shadow comes
          from the past. It will be the same when you will photograph the Milky
          Way: you will make clichés… from the past.
        </p>
        <Image
          id="voie-lactee-integralite"
          image={obtenirImage(images, "voieLacteeIntegralite")}
          langue={page.langue}
          codePageCible="voieLacteeIntegralite360EN"
        />
      </Section>
      <Section titre="The bulb of the Milky Way">
        <p>
          The bulb (or “core”) is the most spectacular region of the Milky Way
          to observe and photograph. Located between the constellations of
          Scorpius and Sagittarius,{" "}
          <strong>
            it is very rich in stars, colorful nebulae and dark clouds
          </strong>
          .
        </p>
        <Image
          id="bulbe-zeta-ophiuchi"
          image={obtenirImage(images, "bulbeZetaOphiuchi")}
          langue={page.langue}
        />
        <p>
          But from the northern hemisphere, we see only one half of the Milky
          Way. Why? Because wherever the place you are on the planet,{" "}
          <strong>
            it is not possible to see the Milky Way in its entirety
          </strong>
          . Thus, in the northern hemisphere, the Milky Way is “cut” by the
          southern horizon at the level of the galactic bulb. If you have the
          chance to travel to the southern hemisphere, you can admire the bulb
          in its entirety as it will be higher in the sky. Do not miss the
          opportunity to isolate you under dark skies to photograph it.
        </p>
      </Section>
      <Section titre="From black and white… to color!">
        <p>
          If the Milky Way appears to your eyes in black and white, you will be
          surprised that your camera, much more sensitive, sees it in color!
          Especially if you point it in direction of the galactic bulb, which is
          rich in colorful nebulae and old golden stars.
        </p>
      </Section>
      <Section titre="Prepare your night">
        <h3>How to spot the Milky Way?</h3>
        <p>
          Although the Milky Way is visible a large part of the year in our
          starry skies, its bulb is visible only from mid-April at the end of
          the night until mid-September early at night. To find it, locate the{" "}
          <strong>Scorpius and Sagittarius constellations</strong> (on the
          southern horizon in the northern hemisphere). You will also have to
          wait for <strong>the Milky Way is high enough</strong> in the sky (at
          least 60° height). Check finally the hours of{" "}
          <strong>moonrise and moonset</strong> because the Moon will not have
          to be up when you take your images. You can easily simulate all these
          settings using a planetarium software like{" "}
          <Lien urlExterne="https://stellarium.org">Stellarium</Lien> or{" "}
          <Lien urlExterne="https://www.ap-i.net/skychart">Skycharts</Lien>.
        </p>
        <Image
          image={obtenirImage(images, "voieLacteeCarteBulbe")}
          langue={page.langue}
        />
        <h3>Where to photograph the Milky Way?</h3>
        <p>
          You must spot a place well <strong>spared by light pollution</strong>.
          In the northern hemisphere, the southern horizon must be clear to
          succeed in your clichés of the Milky Way and its bulb. For that, a
          light pollution map (do an Internet search) will be a precious help.
          The best places to realize these clichés are the mountainous regions
          or the areas weakly populated. If you have a little light pollution on
          your spot, keep in mind that{" "}
          <strong>the zenital part of the Milky Way</strong> is always very
          interesting.
        </p>
        <h3>How to monitor the weather?</h3>
        <p>
          <strong>Of course, avoid clouds</strong>. Multiply your sources of
          weather information to get a reliable forecast. I recommend you to
          check <strong>3 different weather models</strong> before planning an
          astronomical outing. You can do that with the{" "}
          <Lien urlExterne="https://www.windy.com">Windy</Lien> application or
          website.
        </p>
        <Image
          image={obtenirImage(images, "voieLacteeWindy")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Which equipment to use?">
        <h3>Which camera should I use?</h3>
        <p>
          Any camera capable of <strong>long enough exposures</strong> can do
          the job. But for this very demanding subject, a DSLR or an hybrid
          camera will be more efficient because often less noisy in high
          sensitivities. The must is the modded DSLR. Indeed, the intense red of
          some nebulae is cut off by a filter placed in front of the sensor of
          your camera. Some companies propose to modify your camera and replace
          this filter with another one, more tolerant. Attention, after the
          modification you risk losing the manufacturer’s warranty.
        </p>
        <h3>Which photo lens to choose?</h3>
        <p>
          It all depends of the photos you want to take. Avoid{" "}
          <strong>too long focal lengths</strong> (more than 135 mm) because the
          Earth turns and without tracking device, your stars will become oval
          in less than 5 seconds. A <strong>f/2.8 or less</strong> lens is
          ideal. A less luminous optic may be suitable, provided you climb in
          ISO sensitivity or multiply the unit exposures.
        </p>
        <h3>Which accessories?</h3>
        <p>
          A tripod is essential, as well as a remote control{" "}
          <strong>to avoid vibrating the device</strong> during triggering (use
          the self-timer otherwise). For panoramic images, a panoramic head will
          be helpful. Of course, do not forget a second battery and a headlamp
          (with red lighting). Finally, cover up yourself, because even summer,
          the nights can be fresh.
        </p>
        <Image
          image={obtenirImage(images, "voieLacteeMateriel")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Landscapes and Milky Way">
        <h3>Which settings to use?</h3>
        <p>
          Check that your camera is in <strong>RAW</strong> mode. Otherwise, the
          compression of images will destroy the weakest stars and your photo
          will lose details. Open your lens to the maximum. Then determine the
          ISO setting: on my EOS 6D, I remain the most of the time at ISO 3200.
        </p>
        <p>
          Last adjustment: the exposure time. Like the Earth turns on itself,{" "}
          <strong>the more you will use a long focal length</strong>, the more
          the exposure time must be short to get stars not too oval. You must
          set a tolerance. With my 14 mm f/2.8 lens I use a time of 25 seconds.
          With my 135 mm L f/2 lens, the exposure time I use drops to… 5
          seconds.
        </p>
        <p>
          You can get a good indication of maximum exposure time using the{" "}
          <strong>rule of “500”</strong>: it is equal to 500 divided by the
          focal length of your lens. Thus, at 17 mm: 500 / 17 = 29 seconds.
        </p>

        <h3>How to focus?</h3>
        <p>
          The best way is to target a brilliant star and magnify it at maximum
          using the LiveView. Then do the focus manually:{" "}
          <strong>the star must become as small as possible</strong>. Be careful
          if you use a very fast optics (less than f/2.8): the focus is very
          delicate and there is a risk for your photo to be blurred.{" "}
          <strong>
            Also, the “infinite” position of your lens is rarely the good
            adjustment.
          </strong>
        </p>
        <p>
          <strong>If you wish to have a sharp foreground:</strong>
        </p>
        <ul>
          <li>Focus on the foreground using a flashlight.</li>
          <li>
            Use an optic with a short focal length (eg 14 mm) to have both a
            sharp foreground and small stars: the{" "}
            <Lien urlExterne="https://en.wikipedia.org/wiki/Hyperfocal_distance">
              hyperfocal
            </Lien>{" "}
            distance is between 2 and 3 meters for a 14 mm lens set to f/2.8,
            against about 13 meters for a 24 mm lens set to f/1.4.
          </li>
        </ul>
        <h3>Composition</h3>
        <p>
          Always <strong>connect the starry sky and the Earth</strong>: include
          at least one terrestrial element in your composition. And of course,
          apply the elementary photographic composition rules (rule of thirds,
          lines of forces, etc.).
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "voieLacteeBouee")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "voieLacteePhare")}
            langue={page.langue}
          />
        </div>
        <h3>Take series of identical photos to reduce the noise</h3>
        <p>
          If you think to <strong>take a little time</strong> to process your
          image on a computer, take series of identical photos (same framing,
          same exposure time, same sensitivity) to reduce the noise of the final
          image. You can then stack them to simulate a longer exposure.
        </p>
        <h3>Image processing</h3>
        <p>
          Once your photos are taken, you will need to process them on your
          computer. I invite you to read the following article:{" "}
          <Lien codePageCible="traitementImagesVoieLacteeEN" />.
        </p>
        <h3>Problems and solutions</h3>
        <ul>
          <li>
            <strong>My photo is under exposed or too noisy</strong>: increase
            the exposure time, aperture, or ISO sensitivity. You can also
            multiply the shots to stack them with an image processing software
            and thus reduce the noise.
          </li>
          <li>
            <strong>The night is clear, but my photos are “orange”</strong>:
            your spot is probably affected by light pollution. You can process
            your photo in Photoshop (by decreasing the saturation of the
            affected area), but nothing will replace a pure dark sky. Note that
            at the level of the horizon, you will never get a black sky.
          </li>
        </ul>
      </Section>
      <Section titre="Zooming in the Milky Way">
        <p>
          When zooming in some well-known regions of the Milky Way, you will
          reveal the colors and details of our starry sky. We then approach the
          (very) vast subject of deep-sky astrophotography…
        </p>
        <Image
          image={obtenirImage(images, "m8m20LaguneTrifide")}
          langue={page.langue}
        />
        <h3>Equipment</h3>
        <p>
          To achieve this type of image, you must use an{" "}
          <strong>equatorial mount</strong>. This is a motorized system designed
          to track the movement of the stars in the sky. Once aligned with the
          celestial north pole (or south pole if you live in the southern
          hemisphere), an equatorial mount allows you to do long photographic
          exposures without the stars stretching. They will stay punctual, even
          with important focal lengths and important exposure times. I recommend
          you to start with a small equatorial mount that can be mounted on your
          photographic tripod: Skywatcher Star Adventurer and Star Adventurer
          Mini, IOptron Skytracker, Vixen Polarie…{" "}
          <strong>They cost between € 150 and € 400</strong>. For the
          photographic optics, use a lens of at least <strong>50 mm</strong> and
          sufficiently open (f/4 max).
        </p>
        <Image
          image={obtenirImage(
            images,
            "materielUnemontureEquatorialeStarAdventurer"
          )}
          langue={page.langue}
        />
        <h3>Some target ideas</h3>
        <ul>
          <li>
            <strong>In winter</strong>: the Orion constellation.
          </li>
          <li>
            <strong>In summer</strong>: the bulb of the Milky Way and the
            nebulae of the Trifid (M20) and the Lagoon (M8).
          </li>
          <li>
            <strong>Still in summer</strong>: the Cygnus constellation.
          </li>
          <li>
            <strong>Summer end / start of autumn</strong>: the Andromeda galaxy
            (M31).
          </li>
          <li>
            <strong>In autumn</strong>: the open cluster of the Pleiades (M45).
          </li>
        </ul>
        <p>
          Not that some targets (the Orion constellation, M31, M45) are located
          in periphery of the Milky Way disk: use a planetarium software to find
          them in the sky.
        </p>
        <h3>In the field</h3>
        <p>
          Before aligning your equatorial mount on the celestial pole of your
          hemisphere (the polar alignment), you need to{" "}
          <strong>balance at best</strong> your equipment. Turn the axes of your
          mount and check that there is no important unbalance. If you use a
          heavy lens,{" "}
          <strong>
            it is likely that you can not balance your mounting perfectly
          </strong>
          . You will then have to <strong>shorten</strong> the exposure times if
          you have tracking problems (oval stars in the center of the image).
        </p>
        <p>Once balancing is done, you can proceed to polar alignment.</p>
        <p>
          Next, manual focus on a brilliant star and look for the targeted
          object. This step can be a little laborious, especially if you use a
          lens with a long focal length and if the object is not visible to the
          naked eye.
        </p>
        <p>
          If you think you are on your target, tighten the brakes of your
          equatorial mount <strong>without exaggerating</strong> because you
          will probably have to loosen them to adjust your framing. Increase the
          ISO setting of your device (12&nbsp;800 for example) and start an
          exposure of a few seconds. If you do not see your target, it is likely
          that you aimed next to it…
        </p>
        <p>
          Once everything is good, tighten the brakes (always without
          exaggerating), lower your ISO setting and start your exposures with
          the unit exposure times listed below. Accumulate unit exposures as
          much as possible, 2 to 3 hours of total exposure time is a very good
          work base!
        </p>
        <h3>Exposure time</h3>
        <p>
          The unit exposure time to use depends on many factors. The main one is
          the focal length of the lens.
        </p>
        <p>
          For a small mount like the Skywatcher Star Adventurer, use the
          following values:
        </p>
        <ul>
          <li>
            <strong>50 mm and less</strong>: 2 minutes.
          </li>
          <li>
            <strong>Between 50 mm and 150 mm</strong>: 1 minute.
          </li>
          <li>
            <strong>150 mm and more</strong>: 30 seconds.
          </li>
        </ul>
        <h3>Image processing</h3>
        <p>
          Realize darks and flats, then stack them with your unit exposures on a
          computer to get the final image using a software like{" "}
          <Lien urlExterne="http://deepskystacker.free.fr/english/index.html">
            Deep Sky Stacker
          </Lien>
          .
        </p>
      </Section>
    </LayoutTutorielEN>
  )
}
