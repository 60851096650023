import React from "react"
import PropTypes from "prop-types"

import CarteTitreEtDescription from "./CarteTitreEtDescription"

export default function ListeCartesTitreEtDescription({ pages, id }) {
  return (
    <nav className="liste-cartes" id={id}>
      {pages.map(c => {
        return (
          <CarteTitreEtDescription
            key={c.code}
            page={c}
          ></CarteTitreEtDescription>
        )
      })}
    </nav>
  )
}

ListeCartesTitreEtDescription.propTypes = {
  pages: PropTypes.array,
  id: PropTypes.string,
}
